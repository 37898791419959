import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import Section from '../components/Section';
import { Container, Row, Column } from '../components/Grid';
import Button from '../components/Button';
import Video from '../components/Video';
import Icon from '../components/Icon';
import Image from '../components/Image';
import HeroArchive from '../components/HeroArchive';
import Card from '../components/Card';
import '../styles/styles.scss';

const CareerProfile = ({ data, pageContext: { orderLookup, relatedMap } }) => {
  const profile = data.allCareerProfilesYaml.edges[0].node;
  const sortIndex = parseInt(orderLookup[profile.parent.name]);
  const maxCareerIndex = parseInt(orderLookup['max']);

  return (
    <Layout
      title={`${profile.title} | Career Profiles`}
      className="career-profile"
    >
      <div className="career-profile__video-wrapper">
        <HeroArchive
              className="hero-archive--career"
              banner={true}
              heroBanner={"Cooperative Careers: " + profile.title}
              >
          <Video guidSrc={profile.videoSrc} poster={profile.videoPoster} />
        </HeroArchive>

        <div className="career-profile__switch-wrapper">
          {sortIndex == 1 && (
            <Link
              className="career-profile__switch-button career-profile__prev-button"
              to={`/career-profiles/${orderLookup[3]}`}
            >
              <Icon name="arrowleft" className="callout__icon" />
            </Link>
          )}

          {sortIndex > 1 && (
            <Link
              className="career-profile__switch-button career-profile__prev-button"
              to={`/career-profiles/${orderLookup[sortIndex - 1]}`}
            >
              <Icon name="arrowleft" className="callout__icon" />
            </Link>
          )}

          {sortIndex < maxCareerIndex && (
            <Link
              className="career-profile__switch-button career-profile__next-button"
              to={`/career-profiles/${orderLookup[sortIndex + 1]}`}
            >
              <Icon name="arrowright" className="callout__icon" />
            </Link>
          )}

          {sortIndex == maxCareerIndex && (
            <Link
              className="career-profile__switch-button career-profile__next-button"
              to={`/career-profiles/${orderLookup[1]}`}
            >
              <Icon name="arrowright" className="callout__icon" />
            </Link>
          )}
        </div>
      </div>
      <Section>
        <Container>
          <Row>
            <Column size={8}>
              <h3>{profile.introHeader}</h3>
              <p dangerouslySetInnerHTML={{ __html: profile.introParagraph }} />
              <h4 id="about" className="mt-2">
                <h3>{profile.aboutHeader}</h3>
              </h4>
              <p dangerouslySetInnerHTML={{ __html: profile.about }} className="mb-2"/>
              <Row>
                <Column size={6} >
                  <Card
                  title={profile.careerTitle}
                  image={profile.careerImage}
                  headline={profile.careerHeadline}
                  subhead={profile.careerSubhead}
                  duration="2:45"
                >
                  <p>
                  {profile.careerCopy}
                  </p>
                  <div className="card__button">
                    <Button className="icon--download" target="blank" to={profile.careerPDF}>
                      <Icon name="download" marginRight />
                        Career Profile
                    </Button>
                  </div>
                  </Card>
                </Column>
                <Column size={6}>
                <Card
                  title={profile.activityTitle}
                  image={profile.activityImage}
                  headline={profile.activityHeadline}
                  subhead={profile.activitySubhead}
                  duration="2:45"
                >
                  <p>
                  {profile.activityCopy}
                  </p>
                  <div className="card__button">
                    <Button className="icon--download" target="blank" to={profile.activityPDF}>
                      <Icon name="download" marginRight />
                      {profile.activityButton}
                    </Button>
                  </div>
                </Card>
              </Column>
              </Row>
            </Column>
            <Column offset={1} size={3}>
              <Image filename={profile.quoteImage} />
              <div className="profile-quote mt-2">
                <p
                  className="profile-quote__quote"
                  dangerouslySetInnerHTML={{ __html: profile.quote }}
                />
                <p
                  className="profile-quote__attribution"
                  dangerouslySetInnerHTML={{ __html: profile.quoteAttribution }}
                />
              </div>
            </Column>
          </Row>
          <Row className="mt-3">
            <Column>
              <h3 id="additional">Additional Careers to explore</h3>
              <p>
                Get a look inside the day-to-day duties of co-op professionals and inspire the next generation of agriculture innovators with these career profiles.
              </p>
            </Column>
          </Row>
          <Row className={'career-profile__related pb-3'}>
            <Column size={6}>
              {profile.related[0] && (
                <>
                  <Image
                    filename={relatedMap[profile.related[0]].relatedImage}
                  />
                  <h4 className="mt-1">
                    {relatedMap[profile.related[0]].title}
                  </h4>
                  <h5 
                  dangerouslySetInnerHTML={{
                      __html: relatedMap[profile.related[0]].subtitleDescription,
                    }} />
                  <p
                    dangerouslySetInnerHTML={{
                      __html: relatedMap[profile.related[0]].shortDescription,
                    }}
                  />
                  <Button to={`/career-profiles/${profile.related[0]}`}>
                    Learn More
                  </Button>
                </>
              )}
            </Column>
            <Column size={6}>
              {profile.related[1] && (
                <>
                  <Image
                    filename={relatedMap[profile.related[1]].relatedImage}
                  />
                  <h4 className="mt-1">
                    {relatedMap[profile.related[1]].title}
                  </h4>
                  <h5 
                  dangerouslySetInnerHTML={{
                      __html: relatedMap[profile.related[1]].subtitleDescription,
                    }} />
                  <p
                    dangerouslySetInnerHTML={{
                      __html: relatedMap[profile.related[1]].shortDescription,
                    }}
                  />
                  <Button to={`/career-profiles/${profile.related[1]}`}>
                    Learn More
                  </Button>
                </>
              )}
            </Column>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

export default CareerProfile;

export const query = graphql`
  query CareerTemplateQuery($id: String!) {
    allCareerProfilesYaml(filter: { id: { eq: $id } }) {
      edges {
        node {
          title
          sortIndex
          about
          aboutHeader
          aboutPDF
          additionalCareersParagraph
          activateIntro
          activateChecklist
          introHeader
          introParagraph
          subHeader
          related
          relatedImage
          studentActivationPDF
          videoPoster
          videoSrc
          quoteImage
          quote
          quoteAttribution
          careerTitle
          careerImage
          careerHeadline
          careerSubhead
          careerCopy
          careerPDF
          activityTitle
          activityImage
          activityHeadline
          activitySubhead
          activityCopy
          activityButton
          activityPDF
          parent {
            ... on File {
              name
            }
          }
        }
      }
    }
  }
`;
